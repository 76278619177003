import { gql } from '@apollo/client/core';

import {
	GB_VENDOR_BASE_FRAGMENT,
	GB_VENDOR_FRAGMENT
} from '../fragments/gb-vendor.fragment';

export const GET_GB_VENDOR_QUERY = gql`
	query getGbVendor($id: ID, $organization_id: ID) {
		gb_vendor(id: $id, organization_id: $organization_id) {
			...GbVendorFields
		}
	}
	${GB_VENDOR_FRAGMENT}
`;

export const GB_VENDOR_DROPDOWN_QUERIES = {
	gb_csr: gql`
		query getGbCsrs(
			$organization_id: ID!
			$shop_id: ID
			$show_disabled: Boolean
			$current_selection: Int
		) {
			gb_csrs(
				organization_id: $organization_id
				shop_id: $shop_id
				show_disabled: $show_disabled
				current_selection: $current_selection
			) {
				...GbVendorBaseFields
			}
		}
		${GB_VENDOR_BASE_FRAGMENT}
	`,
	gb_location: gql`
		query getGbLocations(
			$organization_id: ID!
			$shop_id: ID
			$show_disabled: Boolean
			$current_selection: Int
		) {
			gb_locations(
				organization_id: $organization_id
				shop_id: $shop_id
				show_disabled: $show_disabled
				current_selection: $current_selection
			) {
				...GbVendorBaseFields
			}
		}
		${GB_VENDOR_BASE_FRAGMENT}
	`,
	gb_salesource: gql`
		query getGbSalesources(
			$organization_id: ID!
			$shop_id: ID
			$show_disabled: Boolean
			$current_selection: Int
		) {
			gb_salesources(
				organization_id: $organization_id
				shop_id: $shop_id
				show_disabled: $show_disabled
				current_selection: $current_selection
			) {
				...GbVendorBaseFields
			}
		}
		${GB_VENDOR_BASE_FRAGMENT}
	`,

	gb_salesrep: gql`
		query getGbSalesreps(
			$organization_id: ID!
			$shop_id: ID
			$show_disabled: Boolean
			$current_selection: Int
		) {
			gb_salesreps(
				organization_id: $organization_id
				shop_id: $shop_id
				show_disabled: $show_disabled
				current_selection: $current_selection
			) {
				...GbVendorBaseFields
			}
		}
		${GB_VENDOR_BASE_FRAGMENT}
	`,
	gb_tech: gql`
		query getGbTechs(
			$organization_id: ID!
			$shop_id: ID
			$show_disabled: Boolean
			$current_selection: [Int]
		) {
			gb_techs(
				organization_id: $organization_id
				shop_id: $shop_id
				show_disabled: $show_disabled
				current_selection: $current_selection
			) {
				...GbVendorBaseFields
			}
		}
		${GB_VENDOR_BASE_FRAGMENT}
	`
};
