interface CustomerDetails {
	Name: string;
	CompanyName: string;
	Status: string;
	Guest: number;
	isInsurance: number;
	isCommercial: number;
	id: number;
}

export interface NewestInvoices {
	jobId: number;
	shopId: number;
	total: number;
	modified: string;
	customer: CustomerDetails;
	billTo: CustomerDetails;
}

export interface DashboardLifetimeData {
	amount: number;
	date: string;
}

export enum TimeRange {
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	YEAR = 'YEAR'
}
