export const getChartTextByMode = (mode: number) => {
	let mainBlockTitle = '';
	let chartTitle = '';

	if (mode === 1) {
		mainBlockTitle = 'Compare revenue trends over time';
		chartTitle = 'Revenue and Number of Invoices';
	} else if (mode === 4) {
		mainBlockTitle = 'See your customer count grow over time';
		chartTitle = 'Number of customers';
	} else if (mode === 3) {
		mainBlockTitle = 'See your invoice count grow over time';
		chartTitle = 'Number of invoices';
	} else {
		mainBlockTitle = 'Monitor average invoice size over time';
		chartTitle = 'Single invoice amount';
	}

	return { mainBlockTitle, chartTitle };
};
