import {
	DashboardLifetimeData,
	TimeRange
} from '@/widgets/dashboard/lib/types';
import { formatDate } from '@/widgets/dashboard/lib/utils';

export const toLabelsAndData = (
	apiData: DashboardLifetimeData[],
	code: TimeRange
) => {
	const data: number[] = [];
	const labels = apiData.map((_, index) => {
		const val = apiData[apiData.length - 1 - index];
		data.push(val.amount);
		return formatDate(val.date, code);
	});
	return { labels, data };
};
