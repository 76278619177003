export const calculatePercentageDifference = (
	currentMonth: number,
	previousMonth: number
) => {
	if (previousMonth === currentMonth) return 0;
	if (previousMonth === 0) return 100;
	const difference = currentMonth - previousMonth;
	const percentageDifference = (difference / previousMonth) * 100;
	return percentageDifference;
};

export const prettifyPercentageDifference = (value: number) => {
	return value >= 0
		? value === 100
			? '+∞'
			: `+${value.toFixed(1)}%`
		: value.toFixed(1) + '%';
};
