import { UserInfo } from './types';

export const permissions = {
	JOB: 2,
	JOBMANAGER: 3,
	MANAGECSRS: 4,
	MANAGESALESREPS: 5,
	MANAGETECHS: 6,
	MANAGESALESSOURCES: 7,
	MANAGELOCATIONS: 8,
	MANAGEINSURANCE: 9,
	MANAGECOMMERCIALACCOUNTS: 10,
	MANAGETAXES: 11,
	ARAP: 12,
	REPORTS: 13,
	ACCOUNTS: 14,
	SHOPS: 15,
	USERS: 16,
	WAREHOUSE: 17,
	NAGS: 18,
	GRAPH: 19,
	PRICING: 20,
	COMMISSION: 21,
	JOBVIEWONLY: 22,
	VIEWPARTS: 23,
	ACCOUNTINGPACKAGES: 24,
	TECHCOMMISSION: 26,
	JOBVOID: 27,
	SCHEDULER: 28,
	DATATAB: 55,
	DATATABJOB: 56,
	DATATABCUSTOMERS: 57,
	DATATABVENDORS: 58
};

export const permissionCheck = function (permission: number, state: UserInfo) {
	// have user permissions
	const tempRoles = state.roles;
	let found = false;
	try {
		for (let i = 0; i < tempRoles.length; i++) {
			if (tempRoles[i].role_id === permission) {
				found = true;
			}
		}
	} catch (err) {
		console.warn(err);
	}
	return found;
};
