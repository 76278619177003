export const getLastUpdatedDate = () => {
	const now = new Date();
	const minutes = now.getMinutes();
	now.setMinutes(Math.floor(minutes / 10) * 10);
	now.setSeconds(0);
	now.setMilliseconds(0);

	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const formattedDate = now.toLocaleString('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
		timeZone
	});
	return formattedDate;
};
