export const getMainChartData = (
	data: number[],
	labels: string[],
	title: string,
	backgroundColor: string
) => {
	return {
		labels,
		datasets: [
			{
				label: title,
				data,
				backgroundColor,
				fill: true,
				barPercentage: 0.5,
				yAxisID: 'y'
			}
		]
	};
};

export const getDualMainChartData = (
	invoiceData: number[],
	revenueData: number[],
	labels: string[]
) => {
	const documentStyle = getComputedStyle(document.documentElement);

	return {
		labels,
		datasets: [
			{
				label: 'Invoices',
				data: invoiceData,
				borderColor: documentStyle.getPropertyValue('--p-gray-300'),
				pointBorderColor: documentStyle.getPropertyValue('--p-gray-300'),
				pointBackgroundColor: documentStyle.getPropertyValue('--p-gray-300'),
				type: 'line',
				fill: false,
				barPercentage: 0.5,
				tension: 0.7,
				yAxisID: 'yRight'
			},
			{
				label: 'Revenue',
				data: revenueData,
				backgroundColor: '#00d0de',
				fill: true,
				barPercentage: 0.5,
				yAxisID: 'y'
			}
		]
	};
};
