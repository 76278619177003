import { TimeRange } from '@/widgets/dashboard/lib/types';

export const getTimeRangeSuffix = (code: TimeRange) => {
	if (code === TimeRange.MONTH) {
		return ' for trailing twelve months (ttm)';
	} else if (code === TimeRange.WEEK) {
		return ' for trailing twenty four weeks (ttw)';
	} else if (code === TimeRange.YEAR) {
		return ' for trailing seven years (tsy)';
	} else {
		return ' for trailing thirty days (ttd)';
	}
};

export const getTimeRangePrefix = (code: TimeRange) => {
	if (code === TimeRange.DAY) {
		return 'TTD';
	} else if (code === TimeRange.MONTH) {
		return 'TTM';
	} else if (code === TimeRange.WEEK) {
		return 'TTW';
	} else if (code === TimeRange.YEAR) {
		return 'TSY';
	}
};
