<script setup lang="ts">
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useRoute } from 'vue-router';

import { MainContainer } from '@/shared/ui';
import DialogContainer from '@/widgets/dialogContainer/DialogContainer.vue';

import { routes } from './router';

const route = useRoute();
</script>

<template>
	<div id="vue3app">
		<MainContainer v-if="routes.some(v => route.path === v.path)" />
		<Toast position="top-center" :pt="{ root: { style: 'top: 62px' } }" />
		<ConfirmDialog />
		<DialogContainer />
	</div>
</template>
