import { DashboardLifetimeData, TimeRange } from './types';

export const formatDate = (dateStr: string, period: TimeRange) => {
	const date = new Date(dateStr);

	if (period === TimeRange.MONTH) {
		return date.toLocaleDateString('en-US', { month: 'long', timeZone: 'UTC' });
	} else if (period === TimeRange.YEAR) {
		return date.getUTCFullYear().toString();
	} else {
		return date.toLocaleString(undefined, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			timeZone: 'UTC'
		});
	}
};

export const formatNumber = (num: number) => {
	let formattedNumber;

	if (num >= 1_000_000) {
		formattedNumber = Math.round(num / 10_000) / 100 + 'm';
	} else if (num >= 1_000) {
		formattedNumber = Math.round(num / 100) / 10 + 'k';
	} else {
		formattedNumber = num.toFixed(0);
	}

	return formattedNumber.toString();
};

export const formatPrice = (num: number | string) => {
	return `$${num}`;
};

export const getChartLifetimeData = (
	canvasId: string,
	data: DashboardLifetimeData[],
	color: string
) => {
	const documentStyle = getComputedStyle(document.documentElement);

	let borderColor: CanvasGradient | string =
		documentStyle.getPropertyValue('--p-blue-500');

	const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
	if (canvas) {
		const ctx = canvas.getContext('2d');

		if (ctx) {
			const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
			gradient.addColorStop(0, `rgba(${color}, 0)`);
			gradient.addColorStop(1, `rgba(${color}, 1)`);
			borderColor = gradient;
		}
	}

	return {
		labels: data.map((_, index) => data[data.length - 1 - index].date),
		datasets: [
			{
				label: 'Invoices',
				data: data.map((_, index) => data[data.length - 1 - index].amount),
				borderColor,
				pointBorderColor: 'transparent',
				pointBackgroundColor: 'transparent',
				fill: false,
				barPercentage: 0.5
			}
		]
	};
};
